<template>
  <!-- <img alt="Vue logo" src="./assets/wewebplus_05478.jpg"> -->
  <home/>
</template>

<script>
import Home from './views/Home'

export default {
  name: 'App',
  components: {
    Home,
  }
}
</script>

<style>
</style>
